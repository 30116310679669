/************************************************

Stylesheet: Main Stylesheet

*************************************************/

#fullscreen {
  position: absolute;
  bottom: 20px;
  left: 20px;
  z-index: 100;
  color: transparent;
  background: transparent;
  border: transparent;
}

html, body {
  overflow: hidden;
}

body {
  margin: 0;
  padding: 0;
  font-family: $barb-font-regular;
  color: map-get($barb-palette, primary-text);
  display: block;
  float: left;
}

strong {
  font-family: $barb-font-bold;
  color: map-get($barb-palette, primary-text);

}

a {
  text-decoration: none;
}

h1 {
  $baseH1: 40px;
  font-size: $baseH1;
  @media screen and (min-width: 1441px) and (max-width: 1680px) {
    font-size: decimal-ceil($baseH1*0.83);

  }
  @media screen and (min-width: 1281px) and (max-width: 1440px) {
    font-size: decimal-ceil($baseH1*0.75);
  }
  @media screen and (min-width: 1025px) and (max-width: 1280px) {
    font-size: decimal-ceil($baseH1*0.666666666666667);
  }
  @media screen and (min-width: 769px) and (max-width: 1024px) {
    font-size: decimal-ceil($baseH1*0.53333333333333);
  }
  @media screen and (max-width: 768px) {
    font-size: decimal-ceil($baseH1*0.4);
  }
  @media only screen and (min-width: 768px) and (orientation: portrait) {
    font-size: $baseH1;
  }
}

h1, h2, h3, h4, h5, h6, p {
  -webkit-margin-before: 0em;
  -webkit-margin-after: 0em;
}

input, textarea, button {
  outline: none;
  font-family: $barb-font-bold;
  -webkit-appearance: none;
  box-shadow: none !important;
}

input:-webkit-autofill, textarea:-webkit-autofill, select:-webkit-autofill {
  background-color: white !important;
}

.br {
  &-circle {
    &__content {

      svg {
        $baseVal: 200px;
        width: $baseVal;
        height: $baseVal;

        @media screen and (min-width: 1441px) and (max-width: 1680px) {
          width: decimal-ceil($baseVal*0.83);
          height: decimal-ceil($baseVal*0.83);
        }
        @media screen and (min-width: 1281px) and (max-width: 1440px) {
          width: decimal-ceil($baseVal*0.75);
          height: decimal-ceil($baseVal*0.75);
        }
        @media screen and (min-width: 1025px) and (max-width: 1280px) {
          width: decimal-ceil($baseVal*0.666666666666667);
          height: decimal-ceil($baseVal*0.666666666666667);
        }
        @media screen and (min-width: 769px) and (max-width: 1024px) {
          width: decimal-ceil($baseVal*0.53333333333333);
          height: decimal-ceil($baseVal*0.53333333333333);
        }
        @media screen and (max-width: 768px) {
          width: decimal-ceil($baseVal*0.4);
          height: decimal-ceil($baseVal*0.4);
        }

        /* only device portrait */
        @media only screen and (min-width: 768px) and (orientation: portrait) {
          width: decimal-ceil($baseVal*0.83);
          height: decimal-ceil($baseVal*0.83);
        }

        path, circle {
          fill: map-get($barb-palette, primary-text);
        }
      }
    }
  }
  &-wrapper {

    &__section {
      position: absolute;
      top: 50%;
      left: 50%;
      //height: 25vw;
      width: 90vw;
      background-color: transparent;
      -webkit-transform: translate(-50%, -50%);
      -moz-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      -o-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);

      &__content {
        &--single {
          display: block;
          width: 100%;
          height: 100%;
          position: relative;
          float: left;
          h1, .content {
            position: absolute;
            text-align: center;
            width: 100%;
            display: block;
            margin: 0;
            @extend %abs-center;

          }
          h1 {
            padding-top: 10px;
          }
          p {
            -webkit-margin-before: 0em;
            -webkit-margin-after: 0em;
            text-align: center;
            font-size: 26px;

            @media screen and (max-width: 1024px) {
              font-size: 22px;
              padding-left: 12px;
              padding-right: 12px;
            }
          }
        }
      }

    }
    &--abstopleft {
      position: absolute;
      z-index: 10;
      $top: 38px;
      $left: 45px;
      top: $top;
      left: $left;
      h1 {
        text-transform: uppercase;

        display: block;
        word-wrap: break-word;
        text-align: center;
        margin: 0 auto;
        font-family: $barb-font-bold;

        $width: 30px;
        $lineH: 50px;
        width: $width;
        line-height: $lineH;
        @media screen and (min-width: 1441px) and (max-width: 1680px) {
          width: decimal-ceil($width*0.83);
          line-height: decimal-ceil($lineH*0.83);
        }
        @media screen and (min-width: 1281px) and (max-width: 1440px) {
          width: decimal-ceil($width*0.75);
          line-height: decimal-ceil($lineH*0.75);
        }
        @media screen and (min-width: 1025px) and (max-width: 1280px) {
          width: decimal-ceil($width*0.666666666666667);
          line-height: decimal-ceil($lineH*0.666666666666667);
        }
        @media screen and (min-width: 769px) and (max-width: 1024px) {
          width: decimal-ceil($width*0.53333333333333);
          line-height: decimal-ceil($lineH*0.53333333333333);
        }
        @media screen and (max-width: 768px) {
          width: decimal-ceil($width*0.4);
          line-height: decimal-ceil($lineH*0.4);
        }

        /* only device portrait */
        @media only screen and (min-width: 768px) and (orientation: portrait) {
          width: decimal-ceil($width*0.83);
          line-height: decimal-ceil($lineH*0.83);
        }

      }
      @media screen and (min-width: 1441px) and (max-width: 1680px) {
        top: decimal-ceil($top*0.83);
        left: decimal-ceil($left*0.83);
      }
      @media screen and (min-width: 1281px) and (max-width: 1440px) {
        top: decimal-ceil($top*0.75);
        left: decimal-ceil($left*0.75);
      }
      @media screen and (min-width: 1025px) and (max-width: 1280px) {
        top: decimal-ceil($top*0.666666666666667);
        left: decimal-ceil($left*0.666666666666667);
      }
      @media screen and (min-width: 769px) and (max-width: 1024px) {
        top: decimal-ceil($top*0.53333333333333);
        left: decimal-ceil($left*0.53333333333333);
      }
      @media screen and (max-width: 768px) {
        top: decimal-ceil($top*0.4);
        left: decimal-ceil($left*0.4);
      }

    }
    &--abstopcenter {
      position: absolute;
      $baseTop: 48px;
      top: $baseTop;
      left: 50%;
      z-index: 10;
      padding: 0 10px;
      -webkit-transform: translateX(-50%);
      -moz-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
      -o-transform: translateX(-50%);
      transform: translateX(-50%);
      @media screen and (min-width: 1441px) and (max-width: 1680px) {
        top: decimal-ceil($baseTop*0.83);
      }
      @media screen and (min-width: 1281px) and (max-width: 1440px) {
        top: decimal-ceil($baseTop*0.75);
      }
      @media screen and (min-width: 1025px) and (max-width: 1280px) {
        top: decimal-ceil($baseTop*0.666666666666667);
      }
      @media screen and (min-width: 769px) and (max-width: 1024px) {
        top: decimal-ceil($baseTop*0.53333333333333);
      }
      @media screen and (max-width: 768px) {
        top: decimal-ceil($baseTop*0.4);
      }
      p {
        border: 3px solid map-get($barb-palette, primary-text);
        background-color: transparent;
        -webkit-border-radius: 37px;
        -moz-border-radius: 37px;
        border-radius: 37px;
        color: map-get($barb-palette, primary-text);

        display: inline-block;
        text-decoration: none;
        -webkit-transition: all 0.35s;
        -moz-transition: all 0.35s;
        -ms-transition: all 0.35s;
        -o-transition: all 0.35s;
        transition: all 0.35s;
        cursor: pointer;
        $baseFontSize: 31.58px;
        $basePaddingHor: 16px;
        $basePaddingVew: 57px;
        padding: $basePaddingHor $basePaddingVew;
        font-size: $baseFontSize;
        font-family: $barb-font-bold;

        @media screen and (min-width: 1441px) and (max-width: 1680px) {
          font-size: decimal-ceil($baseFontSize*0.83);
          padding: decimal-ceil($basePaddingHor*0.83) decimal-ceil($basePaddingVew*0.83);
        }
        @media screen and (max-width: 1440px) {
          border: 2px solid map-get($barb-palette, primary-text);
        }
        @media screen and (min-width: 1281px) and (max-width: 1440px) {
          font-size: decimal-ceil($baseFontSize*0.75);
          padding: decimal-ceil($basePaddingHor*0.75) decimal-ceil($basePaddingVew*0.75);

        }
        @media screen and (min-width: 1025px) and (max-width: 1280px) {
          font-size: decimal-ceil($baseFontSize*0.666666666666667);
          padding: decimal-ceil($basePaddingHor*0.666666666666667) decimal-ceil($basePaddingVew*0.666666666666667);

        }
        @media screen and (min-width: 769px) and (max-width: 1024px) {
          font-size: decimal-ceil($baseFontSize*0.53333333333333);
          padding: decimal-ceil($basePaddingHor*0.53333333333333) decimal-ceil($basePaddingVew*0.53333333333333);

        }
        @media screen and (max-width: 768px) {
          font-size: decimal-ceil($baseFontSize*0.4);
          padding: decimal-ceil($basePaddingHor*0.4) decimal-ceil($basePaddingVew*0.4);

        }

        /* only device portrait */
        @media only screen and (min-width: 768px) and (orientation: portrait) {
          font-size: decimal-ceil($baseFontSize);
          padding: decimal-ceil($basePaddingHor*0.83) decimal-ceil($basePaddingVew*0.83);
        }

        &:hover {
          color: white;
          background-color: map-get($barb-palette, primary-text);
          -webkit-transition: all 0.35s;
          -moz-transition: all 0.35s;
          -ms-transition: all 0.35s;
          -o-transition: all 0.35s;
          transition: all 0.35s;
        }
      }
    }

  }
  &-form {
    &--contact {
      .br-row {
        width: 100%;
        display: block;
        float: left;
        position: relative;
      }
      .former {
        max-width: 100%;
      }
      form {
        float: left;
      }
      /*.br-name {
        @media screen and (max-width: 1024px) and (orientation: landscape) {
          width: 47%;
          margin-right: 5px;
        }
      }
      .br-location {
        @media screen and (max-width: 1024px) and (orientation: landscape) {
          width: 47%;
          margin-left: 5px;
        }
      }*/

      label {
        display: block;
        width: 100%;
        float: left;

        font-family: $barb-font-bold;
        $baseVal: 24px;

        padding-left: $baseVal;
        margin-bottom: $baseVal/4;
        font-size: $baseVal;

        @media screen and (min-width: 1441px) and (max-width: 1680px) {
          padding-left: decimal-ceil($baseVal*0.83);
          margin-bottom: decimal-ceil($baseVal/4*0.83);
          font-size: decimal-ceil($baseVal*0.83);
        }
        @media screen and (min-width: 1281px) and (max-width: 1440px) {
          padding-left: decimal-ceil($baseVal*0.75);
          margin-bottom: decimal-ceil($baseVal/4*0.75);
          font-size: decimal-ceil($baseVal*0.75);
        }
        @media screen and (min-width: 1025px) and (max-width: 1280px) {
          padding-left: decimal-ceil($baseVal*0.666666666666667);
          margin-bottom: decimal-ceil($baseVal/4*0.666666666666667);
          font-size: decimal-ceil($baseVal*0.666666666666667);
        }
        @media screen and (min-width: 769px) and (max-width: 1024px) {
          padding-left: decimal-ceil($baseVal*0.53333333333333);
          margin-bottom: decimal-ceil($baseVal/4*0.53333333333333);
          font-size: decimal-ceil($baseVal*0.53333333333333);
        }
        @media screen and (max-width: 768px) {
          padding-left: decimal-ceil($baseVal*0.4);
          margin-bottom: decimal-ceil($baseVal/4*0.4);
          font-size: decimal-ceil($baseVal*0.4);
        }

        /* only device portrait */
        @media only screen and (min-width: 768px) and (orientation: portrait) {
          padding-left: decimal-ceil($baseVal*0.75);
          margin-bottom: decimal-ceil($baseVal/4*0.75);
          font-size: decimal-ceil($baseVal);
        }

      }
      label, input, textarea {
        position: relative;
      }
      input, textarea {
        background-color: white;
        color: map-get($barb-palette, primary-text);
        font-family: $barb-font-bold;
        border: 2px solid white;

        $paddVer: 10px;
        $paddHor: 20px;
        $fontSize: 34px;
        padding: $paddVer $paddHor;
        font-size: $fontSize;

        @media screen and (min-width: 1441px) and (max-width: 1680px) {
          padding: decimal-ceil($paddVer*0.83) decimal-ceil($paddHor*0.83);
          font-size: decimal-ceil($fontSize*0.83);
        }
        @media screen and (min-width: 1281px) and (max-width: 1440px) {
          padding: decimal-ceil($paddVer*0.75) decimal-ceil($paddHor*0.75);
          font-size: decimal-ceil($fontSize*0.75);
        }
        @media screen and (min-width: 1025px) and (max-width: 1280px) {
          padding: decimal-ceil($paddVer*0.666666666666667) decimal-ceil($paddHor*0.666666666666667);
          font-size: decimal-ceil($fontSize*0.666666666666667);
        }
        @media screen and (min-width: 769px) and (max-width: 1024px) {
          padding: decimal-ceil($paddVer*0.53333333333333) decimal-ceil($paddHor*0.53333333333333);
          font-size: decimal-ceil($fontSize*0.53333333333333);
        }
        @media screen and (max-width: 768px) {
          padding: decimal-ceil($paddVer*0.4) decimal-ceil($paddHor*0.4);
          font-size: decimal-ceil($fontSize*0.4);
        }

      }
      input:not([type="button"]) {

        display: block;
        float: left;

        $baseVal: 40px;
        width: calc(100% - #{$baseVal});

        @media screen and (min-width: 1441px) and (max-width: 1680px) {
          width: calc(100% - #{$baseVal*0.83});
        }
        @media screen and (min-width: 1281px) and (max-width: 1440px) {
          width: calc(100% - #{$baseVal*0.75});
        }
        @media screen and (min-width: 1025px) and (max-width: 1280px) {
          width: calc(100% - #{$baseVal*0.666666666666667});
        }
        @media screen and (min-width: 769px) and (max-width: 1024px) {
          width: calc(100% - #{$baseVal*0.53333333333333});
        }
        @media screen and (max-width: 768px) {
          width: calc(100% - #{$baseVal*0.4});
        }
      }
      button[type="submit"] {

        float: right;
        border: 2px solid map-get($barb-palette, primary-text);
        background-color: map-get($barb-palette, primary-text);
        color: map-get($barb-palette, primary-pink);

        display: inline-block;
        text-decoration: none;
        -webkit-transition: all 0.35s;
        -moz-transition: all 0.35s;
        -ms-transition: all 0.35s;
        -o-transition: all 0.35s;
        transition: all 0.35s;
        cursor: pointer;

        $maxW: 150px;
        $fontBase: 24px;
        $br: 24px;
        $padVer: 8px;
        $padHor: 32px;

        max-width: $maxW;
        font-size: $fontBase;
        border-radius: $br;
        padding: $padVer $padHor;

        @media screen and (min-width: 1441px) and (max-width: 1680px) {
          max-width: $maxW*0.83;
          font-size: $fontBase*0.83;
          border-radius: $br*0.83;
          padding: $padVer*0.83 $padHor*0.83;
        }
        @media screen and (min-width: 1281px) and (max-width: 1440px) {
          max-width: $maxW*0.75;
          font-size: $fontBase*0.75;
          border-radius: $br*0.75;
          padding: $padVer*0.75 $padHor*0.75;
        }
        @media screen and (min-width: 1025px) and (max-width: 1280px) {
          max-width: $maxW*0.666666666666667;
          font-size: $fontBase*0.666666666666667;
          border-radius: $br*0.666666666666667;
          padding: $padVer*0.666666666666667 $padHor*0.666666666666667;
        }
        @media screen and (min-width: 768px) and (max-width: 1024px) {
          max-width: $maxW*0.53333333333333;
          font-size: $fontBase*0.53333333333333;
          border-radius: $br*0.53333333333333;
          padding: $padVer*0.53333333333333 $padHor*0.53333333333333;
        }

        @media screen and (max-width: 767px) {
          max-width: 100%;
          display: block;
          width: 100%;

          font-size: $fontBase*0.4;
          border-radius: $br*0.4;
          padding: $padVer*0.4 $padHor*0.4;

        }

        &:hover, &:focus {
          color: map-get($barb-palette, primary-text);
          background-color: transparent;
          -webkit-transition: all 0.35s;
          -moz-transition: all 0.35s;
          -ms-transition: all 0.35s;
          -o-transition: all 0.35s;
          transition: all 0.35s;
        }
      }
      input {
        $baseVal: 15px;
        border-radius: $baseVal;

        @media screen and (min-width: 1441px) and (max-width: 1680px) {
          border-radius: $baseVal*0.83;
        }
        @media screen and (min-width: 1281px) and (max-width: 1440px) {
          border-radius: $baseVal*0.75;
        }
        @media screen and (min-width: 1025px) and (max-width: 1280px) {
          border-radius: $baseVal*0.666666666666667;
        }
        @media screen and (min-width: 769px) and (max-width: 1024px) {
          border-radius: $baseVal*0.53333333333333;
        }
        @media screen and (max-width: 768px) {
          border-radius: $baseVal*0.4;
        }
      }
      textarea {

        display: block;
        float: left;

        $baseVal: 40px;
        $radius: 37px;
        border-radius: $radius;
        min-height: $baseVal*5;
        max-height: $baseVal*5;

        width: calc(100% - #{$baseVal});

        @media screen and (min-width: 1441px) and (max-width: 1680px) {
          width: calc(100% - #{$baseVal*0.83});
          border-radius: $radius*0.83;
          min-height: $baseVal*5*0.83;
          max-height: $baseVal*5*0.83;
        }
        @media screen and (min-width: 1281px) and (max-width: 1440px) {
          width: calc(100% - #{$baseVal*0.75});
          border-radius: $radius*0.75;
          min-height: $baseVal*5*0.75;
          max-height: $baseVal*5*0.75
        }
        @media screen and (min-width: 1025px) and (max-width: 1280px) {
          width: calc(100% - #{$baseVal*0.666666666666667});
          border-radius: $radius*0.666666666666667;
          min-height: $baseVal*5*0.666666666666667;
          max-height: $baseVal*5*0.666666666666667
        }
        @media screen and (min-width: 769px) and (max-width: 1024px) {
          width: calc(100% - #{$baseVal*0.53333333333333});
          border-radius: $radius*0.53333333333333;
          min-height: $baseVal*5*0.53333333333333;
          max-height: $baseVal*5*0.53333333333333
        }
        @media screen and (max-width: 768px) {
          width: calc(100% - #{$baseVal*0.4});
          border-radius: $radius*0.4;
          min-height: $baseVal*5*0.4;
          max-height: $baseVal*5*0.4
        }

        @media screen and (max-width: 1024px) and (orientation: portrait) {
          min-height: 120px;
        }

        @media screen and (min-width: 641px) and (max-width: 1024px) and (orientation: landscape) {
          min-height: 100px;
        }

        @media screen and (max-width: 640px) and (orientation: landscape) {
          min-height: 70px;
        }

      }
    }
  }
  &-section {
    &--one, &--two, &--five {
      font-family: $barb-font-regular;
      $baseH: 280px;
      height: $baseH;
      @media screen and (min-width: 1441px) and (max-width: 1680px) {
        height: $baseH*0.83;
      }
      @media screen and (min-width: 1281px) and (max-width: 1440px) {
        height: $baseH*0.75;
      }
      @media screen and (min-width: 1025px) and (max-width: 1280px) {
        height: $baseH*0.666666666666667;
      }
      @media screen and (min-width: 769px) and (max-width: 1024px) {
        height: $baseH*0.53333333333333;
      }
      @media screen and (max-width: 768px) {
        height: $baseH*0.4;
      }
    }
    &--one {
      width: 100%;
      max-width: calc((100% - 12.5% - 75px) / 4 * 2 + 25px);

      h1 {
        $margTop: -13px;
        margin-top: $margTop;
        @media screen and (min-width: 1441px) and (max-width: 1680px) {
          margin-top: $margTop*0.83;
        }
        @media screen and (min-width: 1281px) and (max-width: 1440px) {
          margin-top: $margTop*0.75;
        }
        @media screen and (min-width: 1025px) and (max-width: 1280px) {
          margin-top: $margTop*0.666666666666667;
        }
        @media screen and (min-width: 769px) and (max-width: 1024px) {
          margin-top: $margTop*0.53333333333333;
        }
        @media screen and (max-width: 768px) {
          margin-top: $margTop*0.4;
        }
      }
    }
    &--two {
      $margTop: -39px;
      margin-top: $margTop;
      @media screen and (min-width: 1441px) and (max-width: 1680px) {
        margin-top: $margTop*0.83;
      }
      @media screen and (min-width: 1281px) and (max-width: 1440px) {
        margin-top: $margTop*0.75;
      }
      @media screen and (min-width: 1025px) and (max-width: 1280px) {
        margin-top: $margTop*0.666666666666667;
      }
      @media screen and (min-width: 769px) and (max-width: 1024px) {
        margin-top: $margTop*0.53333333333333;
      }
      @media screen and (max-width: 768px) {
        margin-top: $margTop*0.3;
        width: 100%;
        max-width: 70vw;
      }

      /* only device portrait */
      @media only screen and (min-width: 768px) and (orientation: portrait) {
        margin-top: $margTop*0.15;
        width: 100%;
        max-width: 70vw;
      }

      p {
        &:first-child {

          $margTop: 30px;
          $margBot: 1em;
          margin-top: $margTop;
          margin-bottom: $margBot;
          @media screen and (min-width: 1441px) and (max-width: 1680px) {
            margin-top: $margTop*0.83;
            margin-bottom: $margBot*0.83;
          }
          @media screen and (min-width: 1281px) and (max-width: 1440px) {
            margin-top: $margTop*0.75;
            margin-bottom: $margBot*0.75;
          }
          @media screen and (min-width: 1025px) and (max-width: 1280px) {
            margin-top: $margTop*0.666666666666667;
            margin-bottom: $margBot*0.666666666666667;
          }
          @media screen and (min-width: 769px) and (max-width: 1024px) {
            margin-top: $margTop*0.53333333333333;
            margin-bottom: $margBot*0.53333333333333;
          }
          @media screen and (max-width: 768px) {
            margin-top: $margTop*0.4;
            margin-bottom: $margBot*0.4;
          }
        }
        $baseFontSize: 34px;
        font-size: $baseFontSize;
        @media screen and (min-width: 1441px) and (max-width: 1680px) {
          font-size: $baseFontSize*0.83;
        }
        @media screen and (min-width: 1281px) and (max-width: 1440px) {
          font-size: $baseFontSize*0.75;
        }
        @media screen and (min-width: 1025px) and (max-width: 1280px) {
          font-size: $baseFontSize*0.666666666666667;
        }
        @media screen and (min-width: 769px) and (max-width: 1024px) {
          font-size: $baseFontSize*0.53333333333333;
        }
        @media screen and (max-width: 768px) {
          font-size: $baseFontSize*0.4;
        }

        /* only device portrait */
        @media only screen and (min-width: 768px) and (orientation: portrait) {
          font-size: $baseFontSize*0.83;
        }
      }

    }

    &--five {
      max-width: calc((100% - 12.5% - 75px) / 4 * 2 + 140px);
    }

    &--three {
      max-width: calc(1050 / 1920 * 100%);
      z-index: -1;
      h1 {
        text-align: center;
        position: absolute;
        top: -50%;
        left: 50%;
        -webkit-transform: translateX(-50%);
        -moz-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
        -o-transform: translateX(-50%);
        transform: translateX(-50%);
      }
      #start {
        opacity: 0;
      }
      svg {
        overflow: visible;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 50%;
        -webkit-transform: translateX(-50%);
        -moz-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
        -o-transform: translateX(-50%);
        transform: translateX(-50%);
      }
      $baseH: 280px;
      height: $baseH;
      @media screen and (min-width: 1441px) and (max-width: 1680px) {
        height: $baseH*0.83;
      }
      @media screen and (min-width: 1281px) and (max-width: 1440px) {
        height: $baseH*0.75;
      }
      @media screen and (min-width: 1025px) and (max-width: 1280px) {
        height: $baseH*0.666666666666667;
      }
      @media screen and (min-width: 769px) and (max-width: 1024px) {
        max-width: calc(1200 / 1920 * 100%);
        height: $baseH*0.53333333333333;
      }

      @media screen and (max-width: 768px) {
        max-width: calc(1500 / 1920 * 100%);
        height: $baseH*0.7;
      }

      @media screen and (max-width: 500px) {
        max-width: 90%;
        height: $baseH*0.4;
      }
    }

    &--four {

      $margTop: -40px;
      margin-top: $margTop;
      @media screen and (min-width: 1441px) and (max-width: 1680px) {
        margin-top: $margTop*0.83;
      }
      @media screen and (min-width: 1281px) and (max-width: 1440px) {
        margin-top: $margTop*0.75;
      }
      @media screen and (min-width: 1025px) and (max-width: 1280px) {
        margin-top: $margTop*0.666666666666667;
      }
      @media screen and (min-width: 769px) and (max-width: 1024px) {
        margin-top: $margTop*0.53333333333333;
      }
      @media screen and (max-width: 768px) {
        margin-top: $margTop*0.4;
      }
      max-width: calc(100% - 12.5%);

      h1 {
        letter-spacing: -1px;
        $margBot: 99px;
        margin-bottom: $margBot;
        @media screen and (min-width: 1441px) and (max-width: 1680px) {
          margin-bottom: $margBot*0.83;
        }
        @media screen and (min-width: 1281px) and (max-width: 1440px) {
          margin-bottom: $margBot*0.75;
        }
        @media screen and (min-width: 1025px) and (max-width: 1280px) {
          margin-bottom: $margBot*0.666666666666667;
        }
        @media screen and (min-width: 769px) and (max-width: 1024px) {
          margin-bottom: $margBot*0.53333333333333;
        }
        @media screen and (max-width: 768px) {
          margin-bottom: $margBot*0.4;
        }

      }
      h2 {

        line-height: 1;
        $baseValue: 34px;
        font-size: $baseValue;
        margin-top: $baseValue;
        margin-bottom: $baseValue;

        @media screen and (min-width: 1441px) and (max-width: 1680px) {
          font-size: $baseValue*0.83;
          margin-top: $baseValue*0.83;
          margin-bottom: $baseValue*0.83;
        }
        @media screen and (min-width: 1281px) and (max-width: 1440px) {
          font-size: $baseValue*0.75;
          margin-top: $baseValue*0.75;
          margin-bottom: $baseValue*0.75;
        }
        @media screen and (min-width: 1025px) and (max-width: 1280px) {
          font-size: $baseValue*0.666666666666667;
          margin-top: $baseValue*0.666666666666667;
          margin-bottom: $baseValue*0.666666666666667;
        }
        @media screen and (min-width: 769px) and (max-width: 1024px) {
          font-size: $baseValue*0.53333333333333;
          margin-top: $baseValue*0.53333333333333;
          margin-bottom: $baseValue*0.53333333333333;
        }
        @media screen and (max-width: 768px) {
          font-size: $baseValue*0.53333333333333;
          margin-top: $baseValue*0.53333333333333;
          margin-bottom: $baseValue*0.53333333333333;
        }

        /* only device portrait */
        @media only screen and (min-width: 768px) and (orientation: portrait) {
          font-size: $baseValue*1.1;
          margin-top: $baseValue*0.9;
          margin-bottom: $baseValue*0.7;
        }
      }
      p {
        $baseValue: 22px;
        font-size: $baseValue;
        padding: 0 2%;
        line-height: 1.3;
        @media screen and (min-width: 1441px) and (max-width: 1680px) {
          font-size: $baseValue*0.83;
          //padding: 0 $baseValue*0.83;
        }
        @media screen and (min-width: 1281px) and (max-width: 1440px) {
          font-size: $baseValue*0.75;
          //padding: 0 $baseValue*0.75;
        }
        @media screen and (min-width: 1025px) and (max-width: 1280px) {
          font-size: $baseValue*0.666666666666667;
          //padding: 0 $baseValue*0.666666666666667;
        }
        @media screen and (min-width: 769px) and (max-width: 1024px) {
          font-size: $baseValue*0.53333333333333;
          //padding: 0 $baseValue*0.53333333333333;
        }
        @media screen and (max-width: 768px) {
          font-size: $baseValue*0.53333333333333;
          //padding: 0 $baseValue*0.53333333333333;
        }
        /* only device portrait */
        @media only screen and (min-width: 768px) and (orientation: portrait) {
          font-size: $baseValue;
          //padding: 0 $baseValue*0.4;
        }
        @media screen and (max-width: 1700px) {
          /*br{
            display: none;
            visibility: hidden;
          }*/
        }
      }

    }
    &--five {
      form {
        h1 {
          width: 100%;
          text-align: center;
          position: absolute;
          $baseTop: -18%;
          top: $baseTop;
          left: 50%;
          -webkit-transform: translateX(-50%);
          -moz-transform: translateX(-50%);
          -ms-transform: translateX(-50%);
          -o-transform: translateX(-50%);
          transform: translateX(-50%);
          @media screen and (min-width: 1441px) and (max-width: 1680px) {
            top: $baseTop*0.83;
          }
          @media screen and (min-width: 1281px) and (max-width: 1440px) {
            top: $baseTop*0.75;
          }
          @media screen and (min-width: 1025px) and (max-width: 1280px) {
            top: $baseTop*0.666666666666667;
          }
          @media screen and (min-width: 769px) and (max-width: 1024px) {
            top: $baseTop*0.53333333333333;
          }
          @media screen and (max-width: 768px) {
            top: $baseTop*0.4;
          }
        }
      }
      width: 100%;
      max-width: calc((100% - 12.5% - 75px) / 4 * 2 + 25px);
      p {
        position: absolute;
        width: 90%;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        -moz-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        -o-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        text-align: center;
        $baseFontSize: 34px;
        font-size: $baseFontSize;
        line-height: 1.2;

        $margTop: -13px;
        margin-top: $margTop;
        @media screen and (min-width: 1441px) and (max-width: 1680px) {
          font-size: $baseFontSize*0.83;
          margin-top: $margTop*0.83;
        }
        @media screen and (min-width: 1281px) and (max-width: 1440px) {
          font-size: $baseFontSize*0.75;
          margin-top: $margTop*0.75;
        }
        @media screen and (min-width: 1025px) and (max-width: 1280px) {
          font-size: $baseFontSize*0.666666666666667;
          margin-top: $margTop*0.666666666666667;
        }
        @media screen and (min-width: 769px) and (max-width: 1024px) {
          font-size: $baseFontSize*0.53333333333333;
          margin-top: $margTop*0.53333333333333;
        }
        @media screen and (max-width: 768px) {
          font-size: $baseFontSize*0.4;
          margin-top: $margTop*0.1;
        }
        @media screen and (max-width: 1700px) {
          br {
            display: none;
            visibility: hidden;
          }
        }
        /* only device portrait */
        @media only screen and (min-width: 768px) and (orientation: portrait) {
          font-size: $baseFontSize*0.83;
        }
        a {
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }

  &-speech-buble-wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    margin-top: -5px;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);

    &:not(:first-child) {
      //opacity: 0;
    }

    &__content {
      $radius: 90px;
      $padVer: 20px;
      //$height: 176px;
      //$mgBot: 30px;

      //background-color: map-get($barb-palette, primary-text);
      color: map-get($barb-palette, primary-pink);
      position: relative;

      border-radius: $radius;
      padding: 0 $padVer;
      //height: $height;
      //margin-bottom: $mgBot;

      @media screen and (min-width: 1441px) and (max-width: 1680px) {
        border-radius: $radius*0.83;
        padding: 0 $padVer*0.83;
        //height: $height*0.83;
        //margin-bottom: $mgBot*0.83;
      }
      @media screen and (min-width: 1281px) and (max-width: 1440px) {
        border-radius: $radius*0.75;
        padding: 0 $padVer*0.75;
        //height: $height*0.75;
        //margin-bottom: $mgBot*0.75;
      }
      @media screen and (min-width: 1025px) and (max-width: 1280px) {
        border-radius: $radius*0.666666666666667;
        padding: 0 $padVer*0.666666666666667;
        //height: $height*0.666666666666667;
        //margin-bottom: $mgBot*0.666666666666667;
      }
      @media screen and (min-width: 769px) and (max-width: 1024px) {
        border-radius: $radius*0.53333333333333;
        padding: 0 $padVer*0.53333333333333;
        //height: $height*0.53333333333333;
        //margin-bottom: $mgBot*0.53333333333333;
      }
      @media screen and (max-width: 768px) {
        border-radius: $radius*0.4;
        padding: 0 $padVer*0.4;
        //height: $height*0.4;
        //margin-bottom: $mgBot*0.4;
      }

      /* only device portrait */
      @media only screen and (min-width: 768px) and (orientation: portrait) {
        border-radius: $radius;
        padding: 0 $padVer*1.5;
        //height: $height*1.3;
        //margin-bottom: $mgBot;
      }

      p {
        margin: 0;
        text-align: center;
        line-height: 1.25;
        position: absolute;
        top: 50%;
        left: 50%;
        width: 90%;
        -webkit-transform: translate(-50%, -50%);
        -moz-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        -o-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        $fontBaseSize: 34px;
        font-size: $fontBaseSize;
        @media screen and (min-width: 1441px) and (max-width: 1680px) {
          font-size: decimal-ceil($fontBaseSize*0.83);
        }
        @media screen and (min-width: 1281px) and (max-width: 1440px) {
          font-size: decimal-ceil($fontBaseSize*0.75);
        }
        @media screen and (min-width: 1025px) and (max-width: 1280px) {
          font-size: decimal-ceil($fontBaseSize*0.666666666666667);
        }
        @media screen and (min-width: 769px) and (max-width: 1024px) {
          font-size: decimal-ceil($fontBaseSize*0.53333333333333);
        }
        @media screen and (max-width: 768px) {
          font-size: decimal-ceil($fontBaseSize*0.3);
        }

        /* only device portrait */
        @media only screen and (min-width: 768px) and (orientation: portrait) {
          font-size: decimal-ceil($fontBaseSize*0.83);
        }

      }
      /*&:after {
        content: "";
        position: absolute;
        top: calc(100% - 1px);
        left: 50%;
        margin-left: -10px;
        border-width: 10px;
        border-style: solid;
        border-color: map-get($barb-palette, primary-text) transparent transparent transparent;
      }*/
    }
    &__author {
      position: absolute;

      left: 50%;
      -webkit-transform: translateX(-50%);
      -moz-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
      -o-transform: translateX(-50%);
      transform: translateX(-50%);
      $posV: 145px;
      top: calc(100% + #{$posV});
      @media screen and (min-width: 1441px) and (max-width: 1680px) {
        top: calc(100% + #{decimal-ceil($posV*0.83)});
      }
      @media screen and (min-width: 1281px) and (max-width: 1440px) {
        top: calc(100% + #{decimal-ceil($posV*0.75)});
      }
      @media screen and (min-width: 1025px) and (max-width: 1280px) {
        top: calc(100% + #{decimal-ceil($posV*0.666666666666667)});
      }
      @media screen and (min-width: 769px) and (max-width: 1024px) {
        top: calc(100% + #{decimal-ceil($posV*0.53333333333333)});
      }

      @media screen and (max-width: 768px) {
        top: calc(100% + #{decimal-ceil($posV*0.4)});
      }
      p {
        $mgTop: 16px;
        $baseFontSize: 24px;
        text-align: center;
        line-height: 1.25;
        margin-top: $mgTop;
        font-size: $baseFontSize;
        @media screen and (min-width: 1441px) and (max-width: 1680px) {
          margin-top: decimal-ceil($mgTop*0.83);
          font-size: decimal-ceil($baseFontSize*0.83);
        }
        @media screen and (min-width: 1281px) and (max-width: 1440px) {
          margin-top: decimal-ceil($mgTop*0.75);
          font-size: decimal-ceil($baseFontSize*0.75);
        }
        @media screen and (min-width: 1025px) and (max-width: 1280px) {
          margin-top: decimal-ceil($mgTop*0.666666666666667);
          font-size: decimal-ceil($baseFontSize*0.666666666666667);
        }
        @media screen and (max-width: 1024px) {
          margin-top: decimal-ceil($mgTop*0.53333333333333);
          font-size: decimal-ceil($baseFontSize*0.53333333333333);
        }

        /* only device portrait */
        @media only screen and (min-width: 768px) and (orientation: portrait) {
          margin-top: decimal-ceil($mgTop*0.83);
          font-size: decimal-ceil($baseFontSize*0.83);
        }

        br {
          display: inline-block !important;
          visibility: hidden !important;
        }
      }

    }
  }
  &-row {
    display: block;
    position: relative;
    width: 100%;
    $margBot: 20px;
    margin-bottom: $margBot;
    @media screen and (min-width: 1441px) and (max-width: 1680px) {
      margin-bottom: $margBot*0.83;
    }
    @media screen and (min-width: 1281px) and (max-width: 1440px) {
      margin-bottom: $margBot*0.75;
    }
    @media screen and (min-width: 1025px) and (max-width: 1280px) {
      margin-bottom: $margBot*0.666666666666667;
    }
    @media screen and (min-width: 769px) and (max-width: 1024px) {
      margin-bottom: $margBot*0.53333333333333;
    }
    @media screen and (max-width: 768px) {
      margin-bottom: $margBot*0.4;
    }
  }
}

//Unique elements
%trans_04 {
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -ms-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

%trans_02 {
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.br-dot {
  &:after {
    content: '';
    position: absolute;
    width: calc(100% - 4px);
    height: calc(100% - 4px);
    background-color: transparent;
    top: 50%;
    left: 50%;
    visibility: visible;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    border-radius: 50%;
    border: 2px solid map-get($barb-palette, primary-text);
    cursor: pointer;
    opacity: 0;
    display: block;
    -webkit-transition: all 0.4s ease-in-out;
    -moz-transition: all 0.4s ease-in-out;
    -ms-transition: all 0.4s ease-in-out;
    -o-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
    @extend %trans_02;
  }
  &:before {
    $font-size: 18px;
    $lineHeight: 22px;
    font-size: $font-size;
    line-height: $lineHeight;
    width: 90px;

    @media screen and (min-width: 1441px) and (max-width: 1680px) {
      font-size: decimal-ceil($font-size*0.83);
      line-height: decimal-ceil($lineHeight*0.83);
    }
    @media screen and (min-width: 1281px) and (max-width: 1440px) {
      font-size: decimal-ceil($font-size*0.75);
      line-height: decimal-ceil($lineHeight*0.75);
    }
    @media screen and (min-width: 1025px) and (max-width: 1280px) {
      font-size: decimal-ceil($font-size*0.666666666666667);
      line-height: decimal-ceil($lineHeight*0.666666666666667);
    }
    @media screen and (max-width: 1024px) {
      font-size: decimal-ceil($font-size*0.53333333333333);
      line-height: decimal-ceil($lineHeight*0.53333333333333);
    }
  }
  &#S0 {
    &:before {
      content: 'Home';
      display: block;
    }
  }
  &#S1 {
    &:before {
      content: 'About';
      display: block;

    }
  }
  &#S2 {
    &:before {
      content: 'Feedback';
      display: block;

    }
  }
  &#S3 {
    &:before {
      content: 'Why Both?';
      display: block;

    }
  }
  &#S4 {
    &:before {
      content: 'Contact';
      display: block;

    }
  }
}

.br-dot--inner {
  &:before {
    $font-size: 14px;
    $lineHeight: 18px;
    font-size: $font-size;
    line-height: $lineHeight;
    width: 160px;
    @media screen and (min-width: 1441px) and (max-width: 1680px) {
      font-size: decimal-ceil($font-size*0.83);
      line-height: decimal-ceil($lineHeight*0.83);
    }
    @media screen and (min-width: 1281px) and (max-width: 1440px) {
      font-size: decimal-ceil($font-size*0.75);
      line-height: decimal-ceil($lineHeight*0.75);
    }
    @media screen and (min-width: 1025px) and (max-width: 1280px) {
      font-size: decimal-ceil($font-size*0.666666666666667);
      line-height: decimal-ceil($lineHeight*0.666666666666667);
    }
    @media screen and (max-width: 1024px) {
      font-size: decimal-ceil($font-size*0.53333333333333);
      line-height: decimal-ceil($lineHeight*0.53333333333333);
    }
  }

  &#IS0 {
    &:before {
      content: 'Emma Jakeways';
      display: block;
    }
  }
  &#IS1 {
    &:before {
      content: 'Mathieu Grichois';
      display: block;

    }
  }
  &#IS2 {
    &:before {
      content: 'Clair Donelan';
      display: block;

    }
  }
  &#IS3 {
    &:before {
      content: 'Jamie Brunskill';
      display: block;

    }
  }
  &#IS4 {
    &:before {
      content: 'Else Grant';
      display: block;

    }
  }
}

.br-dot, .br-dot--inner {

  @extend %trans_02;
  position: relative;

  &:before {
    -webkit-transition: all 0.4s ease-in-out;
    -moz-transition: all 0.4s ease-in-out;
    -ms-transition: all 0.4s ease-in-out;
    -o-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
    font-family: $barb-font-regular;

    position: absolute;
    right: 200%;
    opacity: 0;
    display: block;
    text-align: right;
    visibility: visible;
    @extend %trans_02;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);

  }

  &:hover {
    -webkit-transition: all 0.4s ease-in-out;
    -moz-transition: all 0.4s ease-in-out;
    -ms-transition: all 0.4s ease-in-out;
    -o-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
    &:after {
      opacity: 1;
      display: block;
      visibility: visible;
    }
    &:before {
      opacity: 1;
      display: block;
      visibility: visible;
    }
  }
}

#logo {
  cursor: pointer;
}

#sorry {
  width: 100%;
  z-index: -10;
  position: fixed;
  top: 0;
  left: 0;

  h1 {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    font-weight: 400;
    font-size: 100%;
    color: white;
    text-align: center;
  }

}

.former {

  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 100%;

  &.success {
    text-align: center;
    $baseVal: 50px;
    font-size: $baseVal;
    color: map-get($barb-palette, primary-pink);

    @media screen and (min-width: 1441px) and (max-width: 1680px) {
      font-size: decimal-ceil($baseVal*0.83);
    }
    @media screen and (min-width: 1281px) and (max-width: 1440px) {
      font-size: decimal-ceil($baseVal*0.75);
    }
    @media screen and (min-width: 1025px) and (max-width: 1280px) {
      font-size: decimal-ceil($baseVal*0.666666666666667);
    }
    @media screen and (max-width: 1024px) {
      font-size: decimal-ceil($baseVal*0.53333333333333);
    }

  }

  &.error {

    text-align: center;

    $baseVal: 42px;
    font-size: $baseVal;
    color: map-get($barb-palette, primary-pink);

    @media screen and (min-width: 1441px) and (max-width: 1680px) {
      font-size: decimal-ceil($baseVal*0.83);
    }
    @media screen and (min-width: 1281px) and (max-width: 1440px) {
      font-size: decimal-ceil($baseVal*0.75);
    }
    @media screen and (min-width: 1025px) and (max-width: 1280px) {
      font-size: decimal-ceil($baseVal*0.666666666666667);
    }
    @media screen and (min-width: 769px) and (max-width: 1024px) {
      font-size: decimal-ceil($baseVal*0.53333333333333);
    }
    @media screen and (max-width: 768px) {
      font-size: decimal-ceil($baseVal*0.4);
    }

  }

}

#loader {
  background-color: map-get($barb-palette, primary-pink);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100000000;
  width: 100%;
  height: 100%;
}

.ishidden {
  opacity: 0;
  height: 0;
  -webkit-transition: all .4s;
  -moz-transition: all .4s;
  -ms-transition: all .4s;
  -o-transition: all .4s;
  transition: all .4s;
}

.br {
  &-dotWrapper {
    position: absolute;
    $baseRight: 35px;
    $baseTop: 43px;
    right: $baseRight;
    top: $baseTop;
    background-color: transparent;
    padding: 8px 16px;
    z-index: 10;
    @media screen and (min-width: 1441px) and (max-width: 1680px) {
      top: $baseTop*0.83;
      right: $baseRight*0.83;
    }
    @media screen and (min-width: 1281px) and (max-width: 1440px) {
      top: $baseTop*0.75;
      right: $baseRight*0.75;
    }
    @media screen and (min-width: 1025px) and (max-width: 1280px) {
      top: $baseTop*0.666666666666667;
      right: $baseRight*0.666666666666667;
    }
    @media screen and (min-width: 769px) and (max-width: 1024px) {
      top: $baseTop*0.53333333333333;
      right: $baseRight*0.53333333333333;
    }
    @media screen and (max-width: 768px) {
      top: $baseTop*0.4;
      right: $baseRight*0.4;
    }
  }
  &-dot {

    display: block;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    position: relative;
    background-color: transparent;
    opacity: 1;
    border: 1px solid map-get($barb-palette, primary-text);
    cursor: pointer;
    $mgBot: 42px;
    $baseW: 16px;

    width: $baseW;
    height: $baseW;
    margin-bottom: $mgBot;
    @media screen and (min-width: 1441px) and (max-width: 1680px) {
      width: decimal-ceil($baseW*0.83);
      height: decimal-ceil($baseW*0.83, 0);
      margin-bottom: decimal-ceil($mgBot*0.83, 0);
    }
    @media screen and (min-width: 1281px) and (max-width: 1440px) {
      width: decimal-ceil($baseW*0.75);
      height: decimal-ceil($baseW*0.75);
      margin-bottom: decimal-ceil($mgBot*0.75);
    }
    @media screen and (min-width: 1025px) and (max-width: 1280px) {
      width: 12px;
      height: 12px;
      margin-bottom: decimal-ceil($mgBot*0.666666666666667);
    }
    @media screen and (min-width: 769px) and (max-width: 1024px) {
      width: 10px;
      height: 10px;
      margin-bottom: decimal-ceil($mgBot*0.53333333333333);
    }
    @media screen and (max-width: 768px) {
      width: 8px;
      height: 8px;
      margin-bottom: decimal-ceil($mgBot*0.4);
    }

    /* only device portrait */
    @media only screen and (min-width: 768px) and (orientation: portrait) {
      width: decimal-ceil($baseW*0.83);
      height: decimal-ceil($baseW*0.83);
      margin-bottom: decimal-ceil($mgBot*0.5);
    }

    &.isactive {
      background-color: map-get($barb-palette, primary-text);
    }
    &:last-child {
      margin-bottom: 0;
    }
    &--inner {
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      border-radius: 50%;
      width: 6px;
      height: 6px;
      background-color: transparent;
      border: 1px solid map-get($barb-palette, primary-text);
      &.isactive {
        background-color: map-get($barb-palette, primary-text);
      }
      cursor: pointer;
      $mgBot: 21px;
      $mgLeft: 0.23rem;
      margin-bottom: $mgBot;
      margin-left: auto;
      margin-right: auto;

      @media screen and (min-width: 1441px) and (max-width: 1680px) {
        margin-bottom: decimal-ceil($mgBot*0.83);
        //margin-left: $mgLeft*0.83;
      }
      @media screen and (min-width: 1281px) and (max-width: 1440px) {
        margin-bottom: decimal-ceil($mgBot*0.75);
        //margin-left: $mgLeft*0.75;
      }
      @media screen and (min-width: 1025px) and (max-width: 1280px) {
        margin-bottom: decimal-ceil($mgBot*0.666666666666667);
        //margin-left: $mgLeft*0.666666666666667;
      }
      @media screen and (min-width: 769px) and (max-width: 1024px) {
        margin-bottom: decimal-ceil($mgBot*0.53333333333333);
        //margin-left: $mgLeft*0.53333333333333;
      }
      @media screen and (max-width: 768px) {
        margin-bottom: decimal-ceil($mgBot*0.4);
        //margin-left: $mgLeft*0.4;
        width: 4px;
        height: 4px;
      }
    }
  }
  &-gif {
    width: calc((100% - 12.5% - 75px) / 4);
    $baseH: 280px;
    height: $baseH;
    display: block;
    float: left;
    @media screen and (min-width: 1441px) and (max-width: 1680px) {
      height: decimal-ceil($baseH*0.83);
    }
    @media screen and (min-width: 1281px) and (max-width: 1440px) {
      height: decimal-ceil($baseH*0.75);
    }
    @media screen and (min-width: 1025px) and (max-width: 1280px) {
      height: decimal-ceil($baseH*0.666666666666667);
    }
    @media screen and (min-width: 769px) and (max-width: 1024px) {
      height: decimal-ceil($baseH*0.53333333333333);
    }
    @media screen and (max-width: 768px) {
      height: decimal-ceil($baseH*0.4);
    }
    @media screen and (max-width: 500px) {
      width: calc((100% - 12.5% - 75px) / 2);
    }

    &--left {
      z-index: 10;
      position: absolute;

      background: url(../images/m1a.gif) no-repeat 50% 50%;
      $bgSize: 342px;
      $mgTop: -41px;
      $mgLeft: 76px;
      background-size: $bgSize;
      margin-top: $mgTop;
      margin-left: $mgLeft;

      @media screen and (min-width: 1441px) and (max-width: 1680px) {

        background-size: decimal-ceil($bgSize*0.83);
        margin-top: decimal-ceil($mgTop*0.83);
        margin-left: decimal-ceil($mgLeft*0.83);
      }
      @media screen and (min-width: 1281px) and (max-width: 1440px) {

        background-size: decimal-ceil($bgSize*0.75);
        margin-top: decimal-ceil($mgTop*0.75);
        margin-left: decimal-ceil($mgLeft*0.75);
      }
      @media screen and (min-width: 1025px) and (max-width: 1280px) {

        background-size: decimal-ceil($bgSize*0.666666666666667);
        margin-top: decimal-ceil($mgTop*0.666666666666667);
        margin-left: decimal-ceil($mgLeft*0.666666666666667);
      }
      @media screen and (min-width: 768px) and (max-width: 1024px) {

        background-size: decimal-ceil($bgSize*0.53333333333333);
        margin-top: decimal-ceil($mgTop*0.53333333333333);
        margin-left: decimal-ceil($mgLeft*0.53333333333333);
      }
      @media screen and (max-width: 768px) {

        background-size: decimal-ceil($bgSize*0.4);
        margin-top: decimal-ceil($mgTop*0.4);
        margin-left: decimal-ceil($mgLeft*0.4);
      }

      @media screen and (min-width: 768px) {
        left: 12.5%;
        top: 50%;
        -webkit-transform: translate(-50%, -50%);
        -moz-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        -o-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
      }

      @media screen and (max-width: 767px) {
        margin-left: initial;
        margin-top: initial;
        left: 50%;
        -webkit-transform: translateX(-50%);
        -moz-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
        -o-transform: translateX(-50%);
        transform: translateX(-50%);
        top: 14%;
      }

      @media screen and (max-width: 500px) {
        top: 17%;
      }

      /* only device portrait */
      @media only screen and (min-width: 768px) and (orientation: portrait) {
        margin-left: initial;
        margin-top: initial;
        left: 50%;
        -webkit-transform: translateX(-50%);
        -moz-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
        -o-transform: translateX(-50%);
        transform: translateX(-50%);
        top: 16%;
        //background-size face gif
        background-size: $bgSize*0.65;
        height: 180px;
      }

      /* only device portrait 1024 */
      @media only screen and (min-width: 1024px) and (orientation: portrait) {
        top: 23%;
      }

    }

    &--secondary {
      text-align: center;
      @media screen and (max-width: 768px) and (orientation: portrait) {
        width: 36vw;
      }

      @media screen and (max-width: 350px) and (orientation: portrait) {
        width: 41vw;
      }

      img {
        opacity: 0;
        width: 100%;
        $maxW: 342px;
        max-width: $maxW;
        position: relative;
        $mgTop: -26px;
        margin-top: $mgTop;

        @media screen and (min-width: 1441px) and (max-width: 1680px) {

          max-width: decimal-ceil($maxW*0.83);
          margin-top: decimal-ceil($mgTop*0.83);

        }
        @media screen and (min-width: 1281px) and (max-width: 1440px) {

          max-width: decimal-ceil($maxW*0.75);
          margin-top: decimal-ceil($mgTop*0.75);
        }
        @media screen and (min-width: 1025px) and (max-width: 1280px) {

          max-width: decimal-ceil($maxW*0.666666666666667);
          margin-top: decimal-ceil($mgTop*0.666666666666667);
        }
        @media screen and (min-width: 768px) and (max-width: 1024px) {

          max-width: decimal-ceil($maxW*0.53333333333333);
          margin-top: decimal-ceil($mgTop*0.53333333333333);
        }
        @media screen and (max-width: 768px) {

          max-width: decimal-ceil($maxW*0.4);
          margin-top: decimal-ceil($mgTop*0.4);
        }

        /*Portrait only */
        @media screen and (max-width: 768px) and (orientation: portrait) {
          max-width: decimal-ceil($maxW*0.83);
          margin-top: decimal-ceil($mgTop*0.83);
        }

        /* only device portrait 1024 */
        @media only screen and (min-width: 1024px) and (orientation: portrait) {
          max-width: decimal-ceil($maxW*0.666666666666667);
          margin-top: decimal-ceil($mgTop*0.666666666666667);
        }

      }
    }

    &--secondary-left {
      z-index: 9;
      position: absolute;

      $mgTop: -41px;
      $mgLeft: 76px;
      //background-size: $bgSize;
      margin-top: $mgTop;
      margin-left: $mgLeft;
      //opacity: 0;
      @media screen and (min-width: 1441px) and (max-width: 1680px) {

        //background-size: $bgSize*0.83;
        margin-top: decimal-ceil($mgTop*0.83);
        margin-left: decimal-ceil($mgLeft*0.83);
      }
      @media screen and (min-width: 1281px) and (max-width: 1440px) {

        //background-size: $bgSize*0.75;
        margin-top: decimal-ceil($mgTop*0.75);
        margin-left: decimal-ceil($mgLeft*0.75);
      }
      @media screen and (min-width: 1025px) and (max-width: 1280px) {

        //background-size: $bgSize*0.666666666666667;
        margin-top: decimal-ceil($mgTop*0.666666666666667);
        margin-left: decimal-ceil($mgLeft*0.666666666666667);
      }
      @media screen and (min-width: 768px) and (max-width: 1024px) {

        //background-size: $bgSize*0.53333333333333;
        margin-top: decimal-ceil($mgTop*0.53333333333333);
        margin-left: decimal-ceil($mgLeft*0.53333333333333);
      }
      @media screen and (max-width: 768px) {

        //background-size: $bgSize*0.4;
        margin-top: decimal-ceil($mgTop*0.4);
        margin-left: decimal-ceil($mgLeft*0.4);
      }

      @media screen and (min-width: 768px) {
        left: 12.5%;
        top: 50%;
        -webkit-transform: translate(-50%, -50%);
        -moz-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        -o-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
      }

      @media screen and (max-width: 767px) {
        margin-left: initial;
        margin-top: initial;
        top: 18.5%;
        left: 50%;
        -webkit-transform: translateX(-50%);
        -moz-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
        -o-transform: translateX(-50%);
        transform: translateX(-50%);
      }

      /* only device portrait */
      @media only screen and (min-width: 768px) and (orientation: portrait) {
        margin-left: initial;
        margin-top: initial;
        width: 29vw;
        height: 180px;
        top: 16%;
        left: 50%;
        -webkit-transform: translateX(-50%);
        -moz-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
        -o-transform: translateX(-50%);
        transform: translateX(-50%);
        //background-size face gif
        //background-size: $bgSize*0.65;
      }

      /* only device portrait 1024 */
      @media only screen and (min-width: 1024px) and (orientation: portrait) {
        top: 23%;
      }

    }
    &--right {
      z-index: 10;

      position: absolute;

      background: url(../images/w1a.gif) no-repeat 50% 50%;
      $bgSize: 342px;
      $mgTop: -41px;
      $mgRight: -83px;
      background-size: $bgSize;
      margin-top: $mgTop;
      margin-left: $mgRight;

      @media screen and (min-width: 1441px) and (max-width: 1680px) {

        background-size: decimal-ceil($bgSize*0.83);
        margin-top: decimal-ceil($mgTop*0.83);
        margin-left: decimal-ceil($mgRight*0.83);
      }
      @media screen and (min-width: 1281px) and (max-width: 1440px) {

        background-size: decimal-ceil($bgSize*0.75);
        margin-top: decimal-ceil($mgTop*0.75);
        margin-left: decimal-ceil($mgRight*0.75);
      }
      @media screen and (min-width: 1025px) and (max-width: 1280px) {

        background-size: decimal-ceil($bgSize*0.666666666666667);
        margin-top: decimal-ceil($mgTop*0.666666666666667);
        margin-left: decimal-ceil($mgRight*0.666666666666667);
      }
      @media screen and (min-width: 768px) and (max-width: 1024px) {

        background-size: decimal-ceil($bgSize*0.53333333333333);
        margin-top: decimal-ceil($mgTop*0.53333333333333);
        margin-left: decimal-ceil($mgRight*0.53333333333333);
      }
      @media screen and (max-width: 768px) {

        background-size: decimal-ceil($bgSize*0.4);
        margin-top: decimal-ceil($mgTop*0.4);
        margin-left: decimal-ceil($mgRight*0.4);
      }

      @media screen and (min-width: 768px) {
        left: 87.5%;
        top: 50%;
        -webkit-transform: translate(-50%, -50%);
        -moz-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        -o-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
      }

      @media screen and (max-width: 767px) {
        margin-top: initial;
        margin-left: initial;
        left: 50%;
        -webkit-transform: translateX(-50%);
        -moz-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
        -o-transform: translateX(-50%);
        transform: translateX(-50%);
        top: 63%;
      }

      /* only device portrait */
      @media only screen and (min-width: 768px) and (orientation: portrait) {
        margin-left: initial;
        margin-top: initial;
        width: 30vw;
        height: 180px;
        top: 63%;
        left: 50%;
        -webkit-transform: translateX(-50%);
        -moz-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
        -o-transform: translateX(-50%);
        transform: translateX(-50%);
        //background-size face gif
        background-size: decimal-ceil($bgSize*0.65);

      }

    }

    &--secondary-right {
      z-index: 9;
      position: absolute;
      //opacity: 0;
      $mgTop: -41px;
      $mgRight: -83px;
      //background-size: $bgSize;
      margin-top: $mgTop;
      margin-left: $mgRight;

      @media screen and (min-width: 1441px) and (max-width: 1680px) {

        //background-size: $bgSize*0.83;
        margin-top: decimal-ceil($mgTop*0.83);
        margin-left: decimal-ceil($mgRight*0.83);
      }
      @media screen and (min-width: 1281px) and (max-width: 1440px) {

        //background-size: $bgSize*0.75;
        margin-top: decimal-ceil($mgTop*0.75);
        margin-left: decimal-ceil($mgRight*0.75);
      }
      @media screen and (min-width: 1025px) and (max-width: 1280px) {

        //background-size: $bgSize*0.666666666666667;
        margin-top: decimal-ceil($mgTop*0.666666666666667);
        margin-left: decimal-ceil($mgRight*0.666666666666667);
      }
      @media screen and (min-width: 768px) and (max-width: 1024px) {

        //background-size: $bgSize*0.53333333333333;
        margin-top: decimal-ceil($mgTop*0.53333333333333);
        margin-left: decimal-ceil($mgRight*0.53333333333333);
      }
      @media screen and (max-width: 768px) {

        //background-size: $bgSize*0.4;
        margin-top: decimal-ceil($mgTop*0.4);
        margin-left: decimal-ceil($mgRight*0.4);
      }

      @media screen and (min-width: 768px) {
        left: 87.5%;
        top: 50%;
        -webkit-transform: translate(-50%, -50%);
        -moz-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        -o-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
      }

      @media screen and (max-width: 767px) {
        margin-top: initial;
        margin-left: initial;
        top: 64%;
        left: 50%;
        -webkit-transform: translateX(-50%);
        -moz-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
        -o-transform: translateX(-50%);
        transform: translateX(-50%);
      }

      /* only device portrait */
      @media only screen and (min-width: 768px) and (orientation: portrait) {
        margin-left: initial;
        margin-top: initial;
        width: 29vw;
        height: 180px;
        top: 63.5%;
        left: 50%;
        -webkit-transform: translateX(-50%);
        -moz-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
        -o-transform: translateX(-50%);
        transform: translateX(-50%);
      }
    }

  }
  &-imessage-wrapper {
    position: absolute;
    width: 200px;
    height: 100px;
    bottom: 0px;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%);
    z-index: 9999;
    @media screen and (max-width: 1024px) and (orientation: landscape) {
      //bottom: -25px;
    }

    p {
      text-align: center;
      width: 100%;
      position: absolute;
      left: 0;
      bottom: 15px;
      opacity: 0;
      display: none;
      visibility: hidden;
      cursor: pointer;
    }
  }
}

#innerDotWrapper {
  position: relative;
  $mgTop: 21px;
  $mgBot: 21px;
  margin-top: $mgTop;
  margin-bottom: $mgBot;
  @media screen and (min-width: 1441px) and (max-width: 1680px) {
    margin-bottom: decimal-ceil($mgBot*0.83);
    margin-top: decimal-ceil($mgTop*0.83);
  }
  @media screen and (min-width: 1281px) and (max-width: 1440px) {
    margin-bottom: decimal-ceil($mgBot*0.75);
    margin-top: decimal-ceil($mgTop*0.75);
  }
  @media screen and (min-width: 1025px) and (max-width: 1280px) {
    margin-bottom: decimal-ceil($mgBot*0.666666666666667);
    margin-top: decimal-ceil($mgTop*0.666666666666667);
  }
  @media screen and (min-width: 769px) and (max-width: 1024px) {
    margin-bottom: decimal-ceil($mgBot*0.53333333333333);
    margin-top: decimal-ceil($mgTop*0.53333333333333);
  }
  @media screen and (max-width: 768px) {
    margin-bottom: decimal-ceil($mgBot*0.4);
    margin-top: decimal-ceil($mgTop*0.4);
  }

}

#arrow {
  width: 16px;
  height: 16px;
  position: absolute;
  bottom: 35px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
  background: url(../images/Arrow.png);
  background-size: contain;
  cursor: pointer;

  @media screen and (max-width: 767px){
    bottom: 20px;
  }

  p {
    margin: 0;
    padding: 0;
    font-size: 12px;
    position: absolute;
    display: none;
    top: -40px;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%);
    opacity: 0;
    width: 200px;
    text-align: center;
  }
  &:hover {
    p {
      display: block;
      opacity: 1;
      -webkit-transition: opacity 0.4s;
      -moz-transition: opacity 0.4s;
      -ms-transition: opacity 0.4s;
      -o-transition: opacity 0.4s;
      transition: opacity 0.4s;
    }
  }
}

#S2 {
  margin-bottom: 0px;
}

#showQuote {
  z-index: 2;
}

#quoteWrapper {
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  #closeQuoteForm {
    position: absolute;
    $baseVal: 76px;

    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    top: $baseVal + 7px;
    width: $baseVal;
    height: $baseVal;
    border-radius: 50%;
    //border: 4px solid map-get($barb-palette, primary-pink);
    background-color: transparent;
    cursor: pointer;
    z-index: 1000;

    @media screen and (min-width: 1441px) and (max-width: 1680px) {
      top: decimal-ceil(($baseVal + 7px)*0.83);
      width: decimal-ceil($baseVal*0.83);
      height: decimal-ceil($baseVal*0.83);

    }
    @media screen and (min-width: 1281px) and (max-width: 1440px) {

      top: decimal-ceil(($baseVal + 7px)*0.75);
      width: decimal-ceil($baseVal*0.75);
      height: decimal-ceil($baseVal*0.75);
    }
    @media screen and (max-width: 1280px) {

      top: decimal-ceil(($baseVal + 7px)*0.666666666666667);
      width: decimal-ceil($baseVal*0.666666666666667);
      height: decimal-ceil($baseVal*0.666666666666667);
    }

    svg {
      width: 100%;
      height: 100%;
      display: block;
      .close-x {
        stroke: map-get($barb-palette, primary-pink);
        fill: transparent;
        stroke-linecap: round;
        stroke-width: 5;

      }
    }

  }
}

#quoteForm {
  width: 100%;
  max-width: calc(100% / 4 * 2);

  @media screen and (min-width: 769px) and (max-width: 1024px) {
    max-width: 60%;
  }

  @media screen and (max-width: 768px) {
    max-width: 92%;
  }
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background-color: transparent;
  padding: 35px;
  margin-top: 70px;

  form {
    position: relative;
    float: left;
    height: 100%;
    width: 100%;
    .stepWrapper {
      position: relative;
      height: 120px;
      float: left;
      width: 100%;
    }
    .step {
      width: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
      -moz-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      -o-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      label {
        position: relative;
        float: left;
        height: 100%;
        width: 100%;
        outline: none;
        border: none;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
        padding: 0px;

        @media screen and (max-width: 768px) {
          padding: 0;
        }

        span.stepCounter {
          position: absolute;
          display: block;
          height: 18px;
          line-height: 100%;
          font-size: 14px;
          font-family: $barb-font-regular;
          color: map-get($barb-palette, primary-pink);
          right: 0;
          top: -20px;
        }

        span.fakePlaceholder {
          position: absolute;
          color: map-get($barb-palette, primary-pink);
          font-family: $barb-font-light;
          $baseVal: 32px;
          font-size: $baseVal;
          line-height: 34px;
          z-index: 10;
          top: 50%;
          left: 50%;
          -webkit-transform: translate(-50%, -50%);
          -moz-transform: translate(-50%, -50%);
          -ms-transform: translate(-50%, -50%);
          -o-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
          width: 100%;
          text-align: center;

          @media screen and (max-width: 768px) {

            font-size: 16px;
          }

          @media screen and (min-width: 1441px) and (max-width: 1680px) {
            font-size: decimal-ceil($baseVal*0.83);
          }
          @media screen and (min-width: 1281px) and (max-width: 1440px) {
            font-size: decimal-ceil($baseVal*0.75);
          }
          @media screen and  (max-width: 1280px) {
            font-size: decimal-ceil($baseVal*0.666666666666667);
          }
          @media screen and (max-width: 768px) {
            width: 90%;

          }
        }

        input {
          position: relative;
          float: left;
          outline: none;
          border: none;
          -webkit-box-shadow: none;
          -moz-box-shadow: none;
          box-shadow: none;
          &.iAmEmpty {
            background-color: #ac96ff !important;
          }

          &[type='text'], &[type='email'] {
            height: 100%;
            width: 100%;
            background-color: map-get($barb-palette, primary-text);
            color: map-get($barb-palette, primary-pink);
            $baseVal: 32px;
            font-size: $baseVal;
            line-height: $baseVal;
            padding: $baseVal+8px 0;
            font-family: $barb-font-light;
            border: none;
            text-align: center;
            &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
              color: map-get($barb-palette, primary-pink);
            }
            &::-moz-placeholder { /* Firefox 19+ */
              color: map-get($barb-palette, primary-pink);
            }
            &:-ms-input-placeholder { /* IE 10+ */
              color: map-get($barb-palette, primary-pink);
            }
            &:-moz-placeholder { /* Firefox 18- */
              color: map-get($barb-palette, primary-pink);
            }

            @media screen and (min-width: 1441px) and (max-width: 1680px) {
              font-size: decimal-ceil($baseVal*0.83);
              padding: decimal-ceil(($baseVal+8px)*0.83) 0;

            }
            @media screen and (min-width: 1281px) and (max-width: 1440px) {
              font-size: decimal-ceil($baseVal*0.75);
              padding: decimal-ceil(($baseVal+8px)*0.75) 0;
            }
            @media screen and (min-width: 1025px) and (max-width: 1280px) {
              font-size: decimal-ceil($baseVal*0.666666666666667);
              padding: decimal-ceil(($baseVal+8px)*0.75) 0;
            }

            @media screen and (max-width: 1024px) {
              font-size: decimal-ceil($baseVal*0.53333333333333);
              padding: decimal-ceil(($baseVal+8px)*0.75) 0;
            }

            @media screen and (max-width: 768px) {
              font-size: 16px;
            }

          }

        }
      }
    }
  }

  .navButtonsWrapper {
    width: 100%;
    position: relative;
    float: left;
    text-align: center;
    .reversor {
      position: relative;
      width: 100%;
      height: 100%;

    }
    .forwardor, .revWrapper {
      position: relative;
      border-radius: 50%;
      //border: 2px solid map-get($barb-palette, primary-text);
      background-color: transparent;
      cursor: pointer;
      z-index: 1000;
      display: inline-block;
      opacity: 0;
      margin: 10px auto 0;
      @extend %trans_02;

      $baseVal: 76px;
      width: $baseVal;
      height: $baseVal;

      @media screen and (min-width: 1441px) and (max-width: 1680px) {
        width: decimal-ceil($baseVal*0.83);
        height: decimal-ceil($baseVal*0.83);

      }
      @media screen and (min-width: 1281px) and (max-width: 1440px) {
        width: decimal-ceil($baseVal*0.75);
        height: decimal-ceil($baseVal*0.75);
      }
      @media screen and (max-width: 1280px) {
        width: decimal-ceil($baseVal*0.666666666666667);
        height: decimal-ceil($baseVal*0.666666666666667);
      }

      svg {
        width: 100%;
        height: 100%;
        position: relative;

        path, polygon {
          @extend %trans_02;
          fill: map-get($barb-palette, primary-pink);
        }
      }

      &:hover {

        svg {

          polygon, path {
            @extend %trans_04;
            fill: #ac96ff;

          }
        }
      }
    }
    .reversor {
      z-index: 3;
      svg {
        -webkit-transform: rotate(180deg);
        -moz-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        -o-transform: rotate(180deg);
        transform: rotate(180deg);
          path, polygon{
            fill: map-get($barb-palette, primary-text) !important;

          }

      }
    }

  }

}

#qSender {
  display: block;
  opacity: 0;
  width: 10px;
  position: absolute;
z-index: 1;
  left: 50%;
  -webkit-transform: translateX(-50%) !important;
  -moz-transform: translateX(-50%) !important;;
  -ms-transform: translateX(-50%) !important;;
  -o-transform: translateX(-50%) !important;;
  transform: translateX(-50%) !important;;
  height: 100%;
  line-height: 100%;
  background-color: transparent;
  outline: none;
  text-align: center;
  cursor: pointer;
  border: 3px solid map-get($barb-palette, primary-pink);
  -webkit-border-radius: 37px;
  -moz-border-radius: 37px;
  border-radius: 37px;
  color: map-get($barb-palette, primary-pink);

  text-decoration: none;
  -webkit-transition: all 0.35s;
  -moz-transition: all 0.35s;
  -ms-transition: all 0.35s;
  -o-transition: all 0.35s;
  transition: all 0.35s;
  $baseFontSize: 31.58px;
  $basePaddingHor: 16px;
  $basePaddingVew: 57px;
  padding: $basePaddingHor $basePaddingVew;
  font-size: $baseFontSize;
  font-family: $barb-font-bold;

  @media screen and (min-width: 1441px) and (max-width: 1680px) {
    font-size: decimal-ceil($baseFontSize*0.83);
    padding: decimal-ceil($basePaddingHor*0.83) decimal-ceil($basePaddingVew*0.83);
  }
  @media screen and (max-width: 1440px) {
    border-width: 2px;
  }
  @media screen and (min-width: 1281px) and (max-width: 1440px) {
    font-size: decimal-ceil($baseFontSize*0.75);
    padding: decimal-ceil($basePaddingHor*0.75) decimal-ceil($basePaddingVew*0.75);

  }
  @media screen and (min-width: 1025px) and (max-width: 1280px) {
    font-size: decimal-ceil($baseFontSize*0.666666666666667);
    padding: decimal-ceil($basePaddingHor*0.666666666666667) decimal-ceil($basePaddingVew*0.666666666666667);

  }
  @media screen and (min-width: 769px) and (max-width: 1024px) {
    font-size: decimal-ceil($baseFontSize*0.53333333333333);
    padding: decimal-ceil($basePaddingHor*0.53333333333333) decimal-ceil($basePaddingVew*0.53333333333333);

  }
  @media screen and (max-width: 768px) {
    font-size: decimal-ceil($baseFontSize*0.4);
    padding: decimal-ceil($basePaddingHor*0.4) decimal-ceil($basePaddingVew*0.4);

  }

  /* only device portrait */
  @media only screen and (min-width: 768px) and (orientation: portrait) {
    font-size: decimal-ceil($baseFontSize);
    padding: decimal-ceil($basePaddingHor*0.83) decimal-ceil($basePaddingVew*0.83);
  }

  &:hover {
    color: white;
    background-color: map-get($barb-palette, primary-text);
    -webkit-transition: all 0.35s;
    -moz-transition: all 0.35s;
    -ms-transition: all 0.35s;
    -o-transition: all 0.35s;
    transition: all 0.35s;
  }
}

.star {
  opacity: 0;
  width: 15px;
  height: 15px;
  position: absolute;
  background: url(../images/star.svg);
  background-size: contain;
}

#sceneWrapper {
  z-index: 1;
  background-color: map-get($barb-palette, primary-pink);
  overflow: hidden;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
}

//simple grid
.col {
  &--4 {
    width: 25%;
    float: left;
    height: 20vw;
    display: block;
    text-align: center;
  }
}

@media screen and (max-width: 768px) {
  br {
    display: none;
    visibility: hidden;
  }
}

@media screen and (max-width: 768px),
screen and (max-width: 1024px) and (orientation: portrait) {
  .carousel {
    width: 100%;
    $baseH: 60vw;
    $maxH: 350px;
    height: $baseH;
    max-height: $maxH;
    overflow: hidden;
    position: relative;
    float: left;
    &-slide-content__text {
      max-width: 300px;
      display: inline-block;
    }
  }

  .pane {
    float: left;
    width: 100%;
    //height: 60vw;
    position: relative;
    &-container {
      width: 100%;
      height: 60vw;
      max-height: 350px;
      list-style: none;
      margin: 0;
      padding: 0;
      position: relative;
    }
    &-container.transition {
      transition: left .4s ease-in-out;
    }
  }
}

/* only device portrait */
@media only screen and (min-width: 768px) and (orientation: portrait) {
  .carousel {
    &-slide-content__text {
      max-width: 380px;
    }
  }
}

//imessage indicator
$gray: #B6B5B9;
$light-gray: #E6E7ED;
$dark-gray: #898989;

@keyframes pulse {
  0%, 100% {
    //background: map-get($barb-palette, primary-pink);
    transform: scale(1.08);
  }

  50% {
    //background: darken(map-get($barb-palette, primary-pink), 20%);
    transform: scale(1);
    background: rgba(map-get($barb-palette, primary-text), 0.25);
  }
}

#imessage_ind {
  display: inline-block;
  background: map-get($barb-palette, primary-text);
  margin: 0em auto;
  $padH: 0.85em;
  $padV: 1.75rem;
  padding: $padH $padV;
  text-align: center;
  border-radius: 20px;
  position: absolute;
  $bot: 45px;
  bottom: $bot;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);

  @media screen and (min-width: 1441px) and (max-width: 1680px) {
    padding: $padH*0.83 $padV*0.83;
  }
  @media screen and (min-width: 1281px) and (max-width: 1440px) {
    padding: $padH*0.75 $padV*0.75;
  }
  @media screen and (max-width: 1280px) {
    padding: $padH*0.666666666666667 $padV*0.666666666666667;
  }

  &:after {
    content: "";
    position: absolute;
    top: calc(100% - 1px);
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: map-get($barb-palette, primary-text) transparent transparent transparent;
  }

  /*@media screen and (min-width: 1441px) and (max-width: 1680px) {
    bottom: decimal-ceil($bot*0.83);
  }
  @media screen and (min-width: 1281px) and (max-width: 1440px) {
    bottom: decimal-ceil($bot*0.75);
  }
  @media screen and (min-width: 1025px) and (max-width: 1280px) {
    bottom: decimal-ceil($bot*0.666666666666667);
  }
  @media screen and (min-width: 769px) and (max-width: 1024px) {
    bottom: decimal-ceil($bot*0.53333333333333);
  }
  @media screen and (max-width: 768px) {
    bottom: decimal-ceil($bot*0.4);
  }*/

  .dots {
    position: relative;
    margin: 0 auto;

    &, &:before, &:after {
      content: "";
      $baseVal: 10px;
      width: $baseVal;
      height: $baseVal;
      background: map-get($barb-palette, primary-pink);
      border-radius: 50%;
      animation: pulse 1.5s linear infinite;

      @media screen and (min-width: 1441px) and (max-width: 1680px) {
        width: decimal-ceil($baseVal*0.83);
        height: decimal-ceil($baseVal*0.83);
      }
      @media screen and (min-width: 1281px) and (max-width: 1440px) {
        width: decimal-ceil($baseVal*0.75);
        height: decimal-ceil($baseVal*0.75);
      }
      @media screen and (max-width: 1280px) {
        width: decimal-ceil($baseVal*0.666666666666667);
        height: decimal-ceil($baseVal*0.666666666666667);
      }
    }

    & {
      animation-delay: 0.66s;
    }

    &:before {
      position: absolute;
      $baseVal: -15px;
      left: $baseVal;
      animation-delay: 0.33s;

      @media screen and (min-width: 1441px) and (max-width: 1680px) {
        left: decimal-ceil($baseVal*0.83);
      }
      @media screen and (min-width: 1281px) and (max-width: 1440px) {
        left: decimal-ceil($baseVal*0.75);
      }
      @media screen and (max-width: 1280px) {
        left: decimal-ceil($baseVal*0.666666666666667);
      }
    }

    &:after {
      position: absolute;
      $baseVal: -15px;
      right: $baseVal;
      animation-delay: 0.99s;
      @media screen and (min-width: 1441px) and (max-width: 1680px) {
        right: decimal-ceil($baseVal*0.83);
      }
      @media screen and (min-width: 1281px) and (max-width: 1440px) {
        right: decimal-ceil($baseVal*0.75);
      }
      @media screen and (max-width: 1280px) {
        right: decimal-ceil($baseVal*0.666666666666667);
      }
    }
  }
}

.contact {
  &-col {

    $baseVal: 270px;
    width: calc(100% - #{$baseVal});

    display: block;
    float: left;

    @media screen and (min-width: 1441px) and (max-width: 1680px) {
      width: calc(100% - #{decimal-ceil($baseVal*0.83)});
    }
    @media screen and (min-width: 1281px) and (max-width: 1440px) {
      width: calc(100% - #{decimal-ceil($baseVal*0.75)});
    }
    @media screen and (min-width: 1025px) and (max-width: 1280px) {
      width: calc(100% - #{decimal-ceil($baseVal*0.666666666666667)});
    }
    @media screen and (min-width: 768px) and (max-width: 1024px) {
      width: calc(100% - #{decimal-ceil($baseVal*0.53333333333333)});
    }

    @media screen and (max-width: 767px) {
      width: 100%;
    }
    /*@media screen and (max-width: 640px) and (orientation: portrait) {
      width: 100%;
      margin-top: 25vw;
    }

    @media screen and (max-width: 1024px) and (orientation: landscape) {
      width: 100%;
    }*/
    &-2 {
      $baseW: 225px;
      width: $baseW;
      float: right;

      @media screen and (min-width: 1441px) and (max-width: 1680px) {
        width: decimal-ceil($baseW*0.83);
      }
      @media screen and (min-width: 1281px) and (max-width: 1440px) {
        width: decimal-ceil($baseW*0.75);
      }
      @media screen and (min-width: 1025px) and (max-width: 1280px) {
        width: decimal-ceil($baseW*0.666666666666667);
      }
      @media screen and (min-width: 768px) and (max-width: 1024px) {
        width: decimal-ceil($baseW*0.53333333333333);
      }

      @media screen and (max-width: 767px) {
        display: none;
      }
      .br-row--image {
        $baseW: 225px;
        height: $baseW;
        width: 100%;
        //background: url(../images/svg/send.svg) right 50% no-repeat;
        //background-size: contain;

        @media screen and (min-width: 1441px) and (max-width: 1680px) {
          height: decimal-ceil($baseW*0.83);
        }
        @media screen and (min-width: 1281px) and (max-width: 1440px) {
          height: decimal-ceil($baseW*0.75);
        }
        @media screen and (min-width: 1025px) and (max-width: 1280px) {
          height: decimal-ceil($baseW*0.666666666666667);
        }
        @media screen and (min-width: 768px) and (max-width: 1024px) {
          height: decimal-ceil($baseW*0.53333333333333);
        }
        @media screen and (max-width: 768px) {
          height: decimal-ceil($baseW*0.4);
        }

        svg.linePLANE {
          width: 100%;
          height: 100%;
          path {
            fill: map-get($barb-palette, primary-text);
            &.tick {
              fill: none;
            }
          }
          @media screen and (max-width: 768px) {
            width: 80%;
            height: 80%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            $mgTop: 15px;
            margin-top: $mgTop;
            @media screen and (min-width: 1441px) and (max-width: 1680px) {
              margin-top: decimal-ceil($mgTop*0.83);
            }
            @media screen and (min-width: 1281px) and (max-width: 1440px) {
              margin-top: decimal-ceil($mgTop*0.75);
            }
            @media screen and (min-width: 1025px) and (max-width: 1280px) {
              margin-top: decimal-ceil($mgTop*0.666666666666667);
            }
            @media screen and (min-width: 768px) and (max-width: 1024px) {
              margin-top: decimal-ceil($mgTop*0.53333333333333);
            }
            @media screen and (max-width: 768px) {
              margin-top: decimal-ceil($mgTop*0.4);
            }
          }

          /* only device portrait */
          @media only screen and (min-width: 768px) and (orientation: portrait) {
            width: 100%;
            height: 100%;
            margin-top: 33px;
          }

        }
        svg.lineSVG {
          width: 56px;
          height: 51px;
          margin-left: -5px;
          margin-top: -3px;
          position: absolute;
          top: 50%;
          left: 50%;
          -webkit-transform: translate(-50%, -50%);
          -moz-transform: translate(-50%, -50%);
          -ms-transform: translate(-50%, -50%);
          -o-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
          path {
            stroke: map-get($barb-palette, primary-text);
          }
        }
      }
    }
  }
}
